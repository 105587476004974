// Merges student marks by semester, adding each label as a key for the semester (e.g., SPI, CGPA)
export const transformStudentMarks = (studentMarks) => {
    return studentMarks.reduce((acc, mark) => {
      const existing = acc.find((entry) => entry.semester === mark.semester);
      if (existing) {
        existing[mark.label.toLowerCase()] = mark.value;
      } else {
        acc.push({
          semester: mark.semester,
          [mark.label.toLowerCase()]: mark.value,
        });
      }
      return acc;
    }, []);
  };
  
  // Ensures only columns with available data (like SPI, CGPA) are displayed in the table.
  export const filterColumns = (data, columns) => {
    return columns.filter((column) => {
      if (column.accessorKey === "semester") return true;
  
      return data.some((entry) => entry.hasOwnProperty(column.accessorKey));
    });
  };