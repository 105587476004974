import { useSelector } from "react-redux"
import { Button } from "../ui/button"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import PdfViewer from "../reusable/PdfViewer"
import { formatMarksType } from "../../utils/marksTypeFormat"
import { ApplicantSemesterColumn } from './ApplicantSemesterColumn';
import {transformStudentMarks,filterColumns} from '../../utils/students-marks'
import { DataTable } from "../../components/table/DataTable"

const ApplicantsInfo = () => {

  const { applicationDetails } = useSelector(state => state.applicationDetailReducer)

  const { resume, loading } = useSelector(state => state.applicantResumeReducer)

  const applicantMarks = applicationDetails?.student?.student_marks ?? [];
  const transformedMarks = transformStudentMarks(applicantMarks);
  const filteredColumns = filterColumns(transformedMarks, ApplicantSemesterColumn);

  const [files, setFiles] = useState([])
  useEffect(() => {
    Promise.all(resume.map(async (file) => {
      return {
        filename: file.filename,
        url: window.URL.createObjectURL(await axios.get(file.signedUrl, {
          responseType: 'arraybuffer'
        }).then((response) => {
          const buffer = new Uint8Array(response.data)
          const blob = new Blob([buffer], { type: 'application/pdf' });
          return blob;
        }), { type: "application/pdf" })
      };
    })).then((files) => {
      setFiles(files)
    });
  }, [loading])

  return (
    <div className="mt-8">
      <div className="grid grid-cols-5 gap-x-6">
        <div className="col-span-2 border shadow-xl px-6 py-4 rounded-lg">
          {
            files.map((file, i) => (
              <PdfViewer key={i} url={file?.url} />
            ))
          }
        </div>
        <div className="col-span-3 border shadow-xl px-6 py-4 rounded-lg">
          <div className="flex flex-col gap-y-4">
            <div>
              <h1 className="font-semibold text-primaryButtonColor text-lg">Application Status</h1>
              <div className="grid grid-cols-3 gap-x-2 mt-2">
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Round</h3>
                  <p className="font-medium">{applicationDetails?.round?.title}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Status</h3>
                  <p className="font-medium">{applicationDetails?.roundStatus?.title}</p>
                </div>
              </div>
            </div>
            <div>
              <h1 className="font-semibold text-primaryButtonColor text-lg">Academic Information</h1>
              <div className="grid grid-cols-3 mt-2 gap-y-2 gap-x-2">
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Class 10 <br />
                    {formatMarksType(applicationDetails?.student?.marks_type_10th)} & Year of Passing
                  </h3>
                  <p className="font-medium">{applicationDetails?.student?.percentage10th}, {applicationDetails?.student?.graduationYear10th}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Class 12 <br />
                    ({formatMarksType(applicationDetails?.student?.marks_type_12th)} & Year of Passing)
                  </h3>
                  <p className="font-medium">{applicationDetails?.student?.percentage12th}, {applicationDetails?.student?.graduationYear12th}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Diploma <br />
                    ({formatMarksType(applicationDetails?.student?.marks_type_diploma)} & Year of Passing)
                  </h3>
                  <p className="font-medium">{applicationDetails?.student?.diplomaPercentage}, {applicationDetails?.student?.diplomaYear}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Bachelor's Degree</h3>
                  <p className="font-medium">{applicationDetails?.student?.branch}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">
                    ({formatMarksType(applicationDetails?.student?.marks_type_graduation)} & Year of Passing)
                  </h3>
                  <p className="font-medium">{applicationDetails?.student?.gpa}, {applicationDetails?.student?.graduationYear}</p>
                </div>
              </div>
              <div className="flex flex-col mt-2">
                  <h3 className="text-tertiaryColor">
                   Semester Marks
                  </h3>
                   {applicantMarks.length > 0 ? (
                     <DataTable hasPagination={false} hasClick={false} columns={filteredColumns} data={transformedMarks} />
                     ) 
                     :( 
                       <p className="font-medium">Semester marks data not available.</p>
                       )}
                </div>
            </div>
            <div>
              <h1 className="font-semibold text-primaryButtonColor text-lg">Job Details</h1>
              <div className="grid grid-cols-3 mt-2">
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Job Title</h3>
                  <p className="font-medium">{applicationDetails?.job?.title}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">CTC Range</h3>
                  <p className="font-medium">{applicationDetails?.job?.ctcMin} - {applicationDetails?.job?.ctcMax}</p>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-tertiaryColor">Duration</h3>
                  <p className="font-medium">{applicationDetails?.job?.jobType}</p>
                </div>
              </div>
            </div>

            <Link to={'/jobs'}>
              <Button variant="primary" size="sm" className="w-fit" >
                Go to Job page
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ApplicantsInfo