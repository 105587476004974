import { connectionStatusEnum } from "../../utils/enums";
import SendInviteModal from "../../components/networks/modals/SendInviteModal";
import WithdrawInviteModal from "../../components/networks/modals/WithdrawInviteModal";
import DeleteInviteModal from "../../components/networks/modals/DeleteInviteModal";
import ViewInvitationModal from "../../components/networks/modals/ViewInvitationModal";
import { useSelector } from "react-redux";

import React from "react";

const ConnectionButton = () => {
  const { networkDetails } = useSelector(
    (state) => state.networkDetailsReducer
  );
  const connectionStatus = networkDetails?.connectionStatus;

  switch (connectionStatus) {
    case connectionStatusEnum.ACCEPTED:
      return (
        <DeleteInviteModal
          connectionStatus={connectionStatusEnum.ACCEPTED}
          connectionId={networkDetails.id}
          collegeName={networkDetails.college.title}
        />
      );

    case connectionStatusEnum.NOT_CONNECTED:
      return (
        <SendInviteModal
          collegeId={networkDetails.college.id}
          collegeName={networkDetails.college.title}
        />
      );

    case connectionStatusEnum.APPROACHED_BY_COLLEGE:
      return (
        <ViewInvitationModal
          description={networkDetails.college.description}
          collegeId={networkDetails.college.id}
          connectionId={networkDetails.id}
          collegeName={networkDetails.college.title}
        />
      );

    case connectionStatusEnum.APPROACHED_BY_COMPANY:
      return (
        <WithdrawInviteModal
          connectionId={networkDetails.id}
          collegeName={networkDetails.college.title}
        />
      );

    case connectionStatusEnum.REJECTED_BY_COMPANY:
      return (
        <DeleteInviteModal
          connectionStatus={connectionStatusEnum.REJECTED_BY_COMPANY}
          connectionId={networkDetails.id}
          collegeName={networkDetails.college.title}
        />
      );
  }
};

export default ConnectionButton;
