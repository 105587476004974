import { useSelector } from "react-redux"

const NetworkDetailsInfo = () => {

    const { networkDetails } = useSelector(state => state.networkDetailsReducer)

    return (
        <div className="py-6">
            <div className="grid grid-cols-8 gap-6">
                <div className="col-span-3 shadow-lg px-4 py-4 flex flex-col gap-y-4 rounded-md border">
                    <div className="flex flex-col gap-y-2">
                        <h1 className="font-semibold text-primaryButtonColor text-lg">About Institute</h1>
                        <p className="">{networkDetails?.college?.description}</p>
                    </div>
                </div>
                <div className="col-span-5 shadow-lg px-4 py-4 flex flex-col gap-y-4 rounded-md border">
                    <h1 className="font-semibold text-primaryButtonColor text-lg">Key Information</h1>

                    <div className="grid grid-cols-3">
                        <div className="flex flex-col gap-y-1">
                            <p className="text-primaryButtonColor font-semibold text-base">Co-Ed Status</p>
                            <p>{networkDetails?.college.coedStatus.join(", ")}</p>
                        </div>
                        <div className="flex flex-col gap-y-1">
                            <p className="text-primaryButtonColor font-semibold text-base">NAAC Grade</p>
                            <p>{networkDetails?.college?.naac_grade}</p>
                        </div>
                        <div className="flex flex-col gap-y-1">
                            <p className="text-primaryButtonColor font-semibold text-base">Medium of Instruction</p>
                            <p>{networkDetails?.college.mediumOfInstruction.join(", ")}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="flex flex-col gap-y-1">
                            <p className="text-primaryButtonColor font-semibold text-base">College Website</p>
                            <p>{networkDetails?.college?.website_url}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <p className="text-primaryButtonColor font-semibold text-base">Courses Offered</p>
                        <p className="font-medium text-secondaryColor">{networkDetails?.college.coursesOffered.join(', ')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NetworkDetailsInfo