import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getDriveTemplateDetails } from "../../redux/features/drive-template/getDriveTemplateDetailsSlice"
import CommonOutlet from "../../components/reusable/layout/CommonOutlet"
import CreateRoundModal from "../../components/drives/modals/CreateRoundModal"
import CommonTabs from "../../components/reusable/tabs/CommonTabs"
import { ToastEnum } from "../../../src/utils/enums"
import { resetError, resetSuccessMsg } from "../../redux/features/drive-template/createTemplateRoundSlice"
import { resetRoundStatusSuccessMsg, resetRoundStatusError } from "../../redux/features/drive-template/createDriveTemplateRoundStatusSlice"
import { resetDeleteRoundStatusError, resetDeleteRoundStatusSuccessMsg } from "../../redux/features/drive-template/deleteDriveTemplateRoundStatusSlice"
import { handleSnackbar } from "../../utils/snackbar-utils"
import { getJobsByRoundTemplateId } from "../../redux/features/jobs/getJobDetailsSlice"
import { getDrivesByRoundTemplateId } from "../../redux/features/drives/getCampusRecruitmentSlice"

const DriveTemplateDetails = () => {

  const { id } = useParams()
  const dispatch = useDispatch()
  const { loading, templateDetails, rounds } = useSelector(state => state.driveTemplateReducer)
  const { loading: createLoading, success: createSuccess, successMsg: createSuccessMsg, error: createError } = useSelector(state => state.createTemplateRoundReducer)
  const { success: createRoundStatusSuccess, successMsg: createRoundStatusSuccessMsg, error: createRoundStatusError } = useSelector(state => state.createDriveTemplateRoundStatusReducer)
  const { success: deleteRoundStatusSuccess, successMsg: deleteRoundStatusSuccessMsg, error: deleteRoundStatusError } = useSelector(state => state.deleteDriveTemplateRoundStatusReducer)

  useEffect(() => {
    const conditions = [
      { type: ToastEnum.SUCCESS, flag: createSuccess, message: createSuccessMsg, action: resetSuccessMsg },
      { type: ToastEnum.SUCCESS, flag: createError, message: createError, action: resetError },
      { type: ToastEnum.SUCCESS, flag: createRoundStatusSuccess, message: createRoundStatusSuccessMsg, action: resetRoundStatusSuccessMsg },
      { type: ToastEnum.ERROR, flag: createRoundStatusError, message: createRoundStatusError, action: resetRoundStatusError },
      { type: ToastEnum.SUCCESS, flag: deleteRoundStatusSuccess, message: deleteRoundStatusSuccessMsg, action: resetDeleteRoundStatusSuccessMsg },
      { type: ToastEnum.ERROR, flag: deleteRoundStatusError, message: deleteRoundStatusError, action: resetDeleteRoundStatusError }
    ];

    conditions.forEach(({ type, flag, message, action }) => {
      handleSnackbar(flag, type, message, dispatch, action);
    });
    dispatch(getDrivesByRoundTemplateId(id))
    dispatch(getDriveTemplateDetails(id))
    dispatch(getJobsByRoundTemplateId(id))
  }, [createSuccess, createError, createRoundStatusSuccess, createRoundStatusError, deleteRoundStatusSuccess, deleteRoundStatusError])

  return (
    <div>
      <CommonOutlet
        hasSearch={false}
        title={!loading && templateDetails?.title}
        createButton={!loading && <CreateRoundModal />}
      >
        <CommonTabs />
      </CommonOutlet>
    </div>
  )
}
export default DriveTemplateDetails