import { LinkedJobsColumns } from '../../../../../src/components/drives/columns/LinkedJobsColumns'
import { DataTable } from '../../../../../src/components/table/DataTable'
import React, { useState } from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ListLoading from "../../../reusable/loaders/ListLoading"

const DriveJobDetails = () => {
    const { loading,jobsByTemplate } = useSelector(state => state.jobDetailReducer)
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
      })
      const navigate = useNavigate()
      const handleRowClick = (row) => {
        const path = `/job-details/${row.id}`;
        navigate(path);
      };
  return (
    <div>
     {
         loading ? <ListLoading /> : jobsByTemplate && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} onClickRoute={handleRowClick} columns={LinkedJobsColumns} data={jobsByTemplate} />
        }
    </div>
  )
}

export default DriveJobDetails
