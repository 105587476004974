import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const updatePrivacyPolicy = createAsyncThunk('updatePrivacyPolicy', async (body, { rejectWithValue }) => {
    try {
        await apiService.post("/privacy-policy")
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const updatePrivacyPolicySlice = createSlice({
    name: "Update Private Policy",
    initialState: {
        loading: false,
        error: null,
        successMsg: null
    },
    reducers: {
        resetPrivacyPolicySuccessMsg: (state, action) => {
            state.successMsg = null
        },
        resetPrivacyPolicyError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePrivacyPolicy.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updatePrivacyPolicy.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = "Privacy Policy Updated Successfully"
        })
        builder.addCase(updatePrivacyPolicy.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export const { resetPrivacyPolicySuccessMsg, resetPrivacyPolicyError } = updatePrivacyPolicySlice.actions
export default updatePrivacyPolicySlice.reducer