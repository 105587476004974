import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getCampusRecruitment = createAsyncThunk('getCampusRecruitment', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get("/drives", query)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const getDrivesByRoundTemplateId = createAsyncThunk('getDrivesByRoundTemplateId', async (roundTemplateId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drives`, {
         roundTemplateId 
        });
        return data;
    } catch (error) {
        console.log(error);
        return rejectWithValue(error);
    }
});


const getCampusRecruitmentSlice = createSlice({
    name: "CampusRecruitment",
    initialState: {
        loading: false,
        error: null,
        drives: [],
        // driveByTemplate:[]
    },
    extraReducers: (builder) => {
        builder.addCase(getCampusRecruitment.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getCampusRecruitment.fulfilled, (state, action) => {
            state.loading = false;
            state.drives = action.payload
        })
        builder.addCase(getCampusRecruitment.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(getDrivesByRoundTemplateId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDrivesByRoundTemplateId.fulfilled, (state, action) => {
            state.loading = false;
            state.drivesByTemplate = action.payload;
        });
        builder.addCase(getDrivesByRoundTemplateId.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
})

export default getCampusRecruitmentSlice.reducer