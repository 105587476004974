import { Icons } from "../../utils/icons"
import navigateBack from "../../utils/backNavigateUtil"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Icon } from "../ui/icon"
import CommonSelect from "../reusable/dropdowns/CommonSelect"
import { useEffect, useState } from "react"
import { ApplicationStatus, ToastEnum } from "../../utils/enums"
import { acceptApplication, rejectApplication, resetUpdateApplicationSuccess } from "../../redux/features/applications/updateApplicationSlice"
import CommonModal from "../reusable/modals/CommonModal"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "../ui/form"
import ButtonLoading from "../reusable/loaders/ButtonLoading"
import { Button } from "../ui/button"
import InputFormField from "../reusable/forms/InputFormField"
import snackbar from "../reusable/snackbar"
import CustomImage from "../reusable/CustomImage"

const formSchema = z.object({
  ctc: z.string().min({
    message: "Please enter the ctc"
  }),
})
const ApplicantDetailsHeader = () => {

  const { applicationId } = useParams()
  const { applicationDetails } = useSelector(state => state.applicationDetailReducer)
  const { loading, successMessage } = useSelector(state => state.updateApplicationReducer)

  const navigate = useNavigate()

  const [openCtcModal, setOpenCtcModal] = useState(false)


  const statusItems = [
    {
      value: ApplicationStatus.ACCEPT,
      label: ApplicationStatus.ACCEPT
    },
    {
      value: ApplicationStatus.REJECT,
      label: ApplicationStatus.REJECT
    },
  ]
  const dispatch = useDispatch()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ctc: ""
    }
  })

  function onSubmit(values) {
    dispatch(acceptApplication({ applicationId, body: { ctc: values.ctc } }))
  }
  const statusFilterChange = (value) => {
    if (!applicationDetails?.actionPending) {
      return snackbar(ToastEnum.WARNING, `User has already been ${applicationDetails?.roundStatus.title} in the last round`)
    }
    if (value === ApplicationStatus.ACCEPT) {
      if (applicationDetails?.isLastRound && applicationDetails?.actionPending) {
        setOpenCtcModal(true)
      }
      else {
        dispatch(acceptApplication({ applicationId }))
      }
    } else if (value === ApplicationStatus.REJECT) {
      dispatch(rejectApplication({ applicationId }))
    }
  }

  useEffect(() => {
    if (successMessage) {
      setOpenCtcModal(false)
      form.reset({
        ctc: ""
      })
      dispatch(resetUpdateApplicationSuccess())
    }
  }, [successMessage])


  return (
    <div className="relative">
      <div className="flex items-center cursor-pointer hover:bg-primaryButtonColor/90 justify-center absolute -top-2 -left-4 w-10 h-10 rounded-full bg-primaryButtonColor p-2">
        <Icon onClick={() => navigateBack(navigate)} icon={Icons.BACKARROW} className="text-lightColor w-5 h-5" />
      </div>
      <div className="grid grid-cols-3 gap-x-6">
        <div className="col-span-2 shadow-lg rounded-lg px-6 py-6 border">
          <div className="flex items-center gap-x-4">
            <div>
              <CustomImage className="w-16 h-16 rounded-full object-cover" src={applicationDetails?.student?.profilePic} alt="" section="student"   />
            </div>
            <div className="flex flex-col">
              <h1 className="text-2xl font-semibold">{applicationDetails?.student?.name}</h1>
              <div className="flex items-center gap-x-2">
                <p className="font-medium">{applicationDetails?.student?.email}</p> |
                <p className="font-medium">{applicationDetails?.student?.phoneNumber}</p> |
                <p className="font-medium">Gender : <span className="font-normal">{applicationDetails?.student?.gender}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-lg rounded-lg px-6 py-6 border">
          <div>
            <h1 className="font-semibold text-primaryButtonColor text-lg">Set Application Status</h1>
            {
              statusItems &&
              <CommonSelect hideNone={true} handleSelectChange={statusFilterChange} selectPlaceholder={"Applicant Status"} items={statusItems} />
            }
          </div>
        </div>
      </div>

      <CommonModal open={openCtcModal} setOpen={setOpenCtcModal} title={`Enter the ctc to be offered to ${applicationDetails?.student?.name}`}>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-2">
            <InputFormField name="ctc" control={form.control} placeholder={"Enter the ctc offered"} label={"Ctc"} type={"text"} />
            {
              loading ? <ButtonLoading /> :
                <div className="flex justify-end w-full">
                  <Button type="submit" variant="primary" className="w-fit rounded-3xl shadow-md mt-4 py-5 text-base">Submit</Button>
                </div>
            }
          </form>
        </Form>
      </CommonModal>
    </div>
  )
}

export default ApplicantDetailsHeader