import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button"
import {
  Form,
} from "../components/ui/form"
import InputFormField from "../components/reusable/forms/InputFormField"
import ButtonLoading from "../components/reusable/loaders/ButtonLoading"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import email from '../assets/icons/mail.png';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from "../config/firebaseConfig"
import { toast } from 'sonner';

const formSchema = z.object({
  email: z.string().email(),
});

const Forgot = () => {
  const [loading, setLoading] = useState(false);
  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  }); 

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, data.email); 
      setEmailLinkSent(true); 
    } catch (error) {
        let errorMessage;
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'No user found with this email address.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Invalid email address.';
            break;
          default:
            errorMessage = 'Error sending password reset email. Please try again.';
        }
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="shadow-lg border rounded-2xl px-6 py-6 max-w-md">
        {emailLinkSent ? ( 
          <div className="flex flex-col items-center gap-y-4 justify-center">
             <img src={email} className="max-w-[50%]" alt="" />
            <h1 className="text-primaryButtonColor font-bold text-2xl">Check Your Mail</h1>
            <p className="text-gray-600 text-center">
             Please check your mail. We have sent you a email that contains a link to reset your password.
            </p>
            <Link to="/login" className="block text-primaryButtonColor text-sm text-center mt-4 hover:underline">
              <Button type="submit" variant="primary" className="w-full mt-4 py-5 text-base">
              Back to Login
              </Button>
            </Link>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-y-4 justify-center">
            <h1 className="text-primaryButtonColor font-bold text-2xl">FORGOT PASSWORD</h1>
            <p className="text-gray-600 text-left text-sm"> 
              Enter the email address associated with your account and we will send you an email with instructions to reset your password.
            </p>
          </div>
        )}
        {!emailLinkSent && ( 
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-6"> 
              <InputFormField
                name="email"
                control={form.control}
                placeholder={"Enter your Email"}
                label={"Email"}
                type={"email"}
              />
              {
              loading ? <ButtonLoading /> :
                <Button type="submit" variant="primary" className="w-full mt-4 py-5 text-base">Send Reset Link</Button>
            }
              <Link to="/login" className="block text-primaryButtonColor text-sm text-center mt-4 hover:underline">
                Back to Login
              </Link>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
}

export default Forgot;
