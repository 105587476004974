import CustomImage from "../../reusable/CustomImage";

const RecentlyConnectedCollegeCard = ({ logo, name }) => {
  return (
    <div className="flex items-center gap-x-4">
      <CustomImage
        className="w-10 h-10 rounded-full object-contain"
        src={logo}
        alt=""
        section="network"
      />
      <p className="font-medium text-sm line-clamp-2">{name}</p>
    </div>
  );
};
export default RecentlyConnectedCollegeCard;
