import { RouterFn } from './Routes'
import './App.css';
import { Toaster } from './components/ui/sonner';
import React, { useEffect, useState } from 'react';
import { auth } from './config/firebaseConfig';
import { useDispatch } from 'react-redux';
import { firebaseSetUser, logout } from './redux/features/auth/authSlice';
import apiService from './services/ApiService';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import { ImageProvider } from './context/ImageContext';
import network from '../src/assets/icons/networks/networksClosedActive.png'
import imageUnavailable from '../src/assets/icons/image_unavailable.png'
import profile from '../src/assets/images/profilePic.png';
import PrivacyPolicy from './components/reusable/PrivacyPolicy';
import { updatePrivacyPolicy } from "./redux/features/policy/updatePolicySlice"

// Initialize Google Analytics only in production
(window.location.hostname === 'company.joinsaarthi.com' || window.location.hostname === 'www.company.joinsaarthi.com') && ReactGA.initialize("G-YMP62ZQG49");



function App() {
// Track the initial page load only in production
(window.location.hostname === 'company.joinsaarthi.com' || window.location.hostname === 'www.company.joinsaarthi.com') && ReactGA.send({ hitType: 'pageview', page: window.location.pathname });


const fallbacks = {
  student: profile,
  network:network,
  profile:profile,
  default: imageUnavailable // Fallback if section not specified
};
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(null); // Initially, we don't know if the policy is accepted
  const [showPopover, setShowPopover] = useState(false);
  const navigate = useNavigate();

  const checkUser = () => {
    auth.onAuthStateChanged((async (user) => {
      if (user) {
        try {
          const response = await apiService.get(`/current-user`);
          const { privacyPolicyAcceptedOn } = response.data;
          if (privacyPolicyAcceptedOn) {
            setIsPolicyAccepted(true);
            setLoading(false);
          } else {
            // If the policy is not accepted, show the popover
            setShowPopover(true);
          }
          dispatch(firebaseSetUser(response.data));

          setLoading(false);
        } catch (error) {
          // Handle any errors here
          console.log(error)
          setLoading(false)
        }
      } else {
        setLoading(false);
      }
    }))
  }
  useEffect(() => {
    checkUser()
  }, [dispatch]);

  if (loading) {
    return <div>Loading</div>
  }
  if (showPopover) {
    return (
      <PrivacyPolicy
        open={showPopover}
        onOpenChange={setShowPopover}
        onAccept={async () => {

          try {
    
            dispatch(updatePrivacyPolicy())
            setShowPopover(false);
            navigate("/");
          } catch (error) {
            console.error(error);
          }
        }}
        onDecline={async () => {
          dispatch(logout()); 
          setShowPopover(false); 
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <ImageProvider fallbacks={fallbacks}>
      <Toaster richColors={true} />
      <RouterFn />
      </ImageProvider>
    </React.Fragment>
  );
}

export default App;
