
import { useImageFallback } from '../../context/ImageContext'
import React from 'react';

const CustomImage = ({ src, alt, section, ...props }) => {
  const fallbacks = useImageFallback();
  const fallbackSrc = fallbacks.fallbacks[section] || fallbacks.fallbacks.default;
  return (
    <img
      src={src || fallbackSrc} 
      alt={alt}
      onError={(e) => {
        e.target.src = fallbackSrc; // Fallback on error
      }}
      {...props}
    />
  );
};

export default CustomImage;