import { DataTable } from "../../../table/DataTable"
import {useState } from "react"
import { useSelector } from "react-redux"
import { DriveColumns } from "../../columns/DriveColumns"
import ListLoading from "../../../reusable/loaders/ListLoading"
import { useNavigate } from "react-router-dom"



const DriveByTemplate = () => {
    const { loading, drivesByTemplate } = useSelector(state => state.campusRecruitmentReducer)
  
    const { driveTemplates } = useSelector(state => state.driveTemplateReducer)
    const { jobs } = useSelector(state => state.jobsReducer)

    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
    })
    const templateItems = driveTemplates?.results?.map((template) => {
        return {
            value: template.id,
            label: template.title
        }
    })
    const jobTitleItems = jobs?.results?.map((job) => {
        return {
            value: job.id,
            label: job.title
        }
    })
    const navigate = useNavigate()
    const handleRowClick = (row) => {
        const path = `/drive-details/${row.id}`;
        navigate(path);
    };
  return (
    <div>
      {
        loading ? <ListLoading /> : drivesByTemplate && <DataTable hasClick={true} pagination={pagination} setPagination={setPagination} onClickRoute={handleRowClick} columns={DriveColumns({ jobTitleItems, templateItems })} data={drivesByTemplate} />
     }
    </div>
  )
}

export default DriveByTemplate
