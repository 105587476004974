import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";

const PrivacyPolicy = ({ open, onOpenChange, onAccept, onDecline }) => {
  return (
    <Dialog className="w-[600px]" open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger>Open</DialogTrigger> */}
      <DialogContent
        className="w-[500px] max-h-[500px] overflow-y-auto"
        allowPrimitiveClose={false}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-center border-b pb-4 border-gray-300">
            END USER LICENSE AGREEMENT
          </DialogTitle>
          <DialogDescription className="">
            <div className="text-justify">
            <p className="mb-2 mt-2">
              <strong>
                PLEASE READ THIS CAREFULLY BEFORE DOWNLOADING AND INSTALLING THE
                SOFTWARE
              </strong>
            </p>
            <p  className="mb-2">
              BY DOWNLOADING, INSTALLING, OR USING THE SOFTWARE, YOU ACKNOWLEGE
              THAT YOU HAVE OBTAINED THE SOFTWARE FROM AN APPROVED SOURCE AND
              YOU AGREE TO BE LEGALLY BOUND BY THE TERMS OF THIS AGREEMENT. IF
              YOU ARE ACCEPTING THESE TERMS ON BEHALF OF ANOTHER PERSON, COMPANY
              OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE
              FULL AUTHORITY TO BIND THAT PERSON, COMPANY OR LEGAL ENTITY TO
              THESE TERMS.
            </p>
            <p>IF YOU DO NOT AGREE TO THESE TERMS:</p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                DO NOT DOWNLOAD, INSTALL, COPY, ACCESS, OR USE THE SOFTWARE; AND
              </li>
              <li>PROMPTLY DELETE OR RETURN THE SOFTWARE FOR A FULL REFUND.</li>
            </ul>
            <p className="mb-2">
              THE LICENSOR RESERVES THE RIGHT TO MODIFY OR REPLACE ANY PART OF
              THIS AGREEMENT AT ANY TIME AT ITS SOLE DISCRETION. WE, THE
              LICENSOR, SHALL USE COMMERCIALLY REASONABLE EFFORTS TO COMMUNICATE
              ANY MATERIAL CHANGES TO THIS AGREEMENT THROUGH NOTIFICATION ON THE
              AUTHORIZED WEBSITE WHERE WE MAKE THIS SOFTWARE AVAILABLE FOR USE
              AND/OR AND ANY OTHER REASONABLE MEANS OF COMMUNICATION. YOUR
              CONTINUED USE OF OR ACCESS TO THE SOFTWARE AND SERVICES FOLLOWING
              THE NOTIFICATION OF SUCH CHANGES TO THIS AGREEMENT CONSTITUTES
              ACCEPTANCE OF THOSE CHANGES.
            </p>
            <p className="mb-2">
              This End User License Agreement (hereinafter referred to as the{" "}
              <strong>&ldquo;Agreement&rdquo;</strong>) is entered into on the
              date the Licensee agrees to the terms of this Agreement{" "}
              <strong>(&ldquo;Effective Date&rdquo;</strong>)
            </p>
            <p className="mb-2">
              <strong>BY AND BETWEEN</strong>
            </p>
            <p className="mb-2">
              <strong>Amaxa Tech Solutions</strong>, an LLP &nbsp;registered
              under the laws of India, and having its registered office at MIG2
              B903, Sardar Heights, (Phase-1), Near Shanti Nagar, Tithal Road,
              Valsad, Gujarat-396001, hereinafter referred to as the{" "}
              <strong>&ldquo;Licensor&rdquo;</strong>, which expression shall
              be, unless repugnant to the context, deemed to include its
              successors in interest and permitted assigns of the{" "}
              <strong>FIRST PART;</strong>
            </p>
            <p className="mb-2">
              <strong>AND</strong>
            </p>
            <p className="mb-2">
              <strong>The End-User of the Software, </strong>either an
              individual, company, or other legal entity, hereinafter referred
              to as the <strong>&ldquo;Licensee&rdquo;</strong>, which
              expression shall, unless it be repugnant to the context, be deemed
              to include its successors in interest &amp; permitted assigns of
              the <strong>SECOND PART;</strong>
            </p>
            <p className="mb-2">
              <em>
                Licensor and Licensee shall be individually referred to as the{" "}
              </em>
              <strong>
                <em>&ldquo;Party&rdquo;</em>
              </strong>
              <em> and collectively referred to as the </em>
              <strong>
                <em>&ldquo;Parties&rdquo;</em>
              </strong>
              <em>.</em>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>1. DEFINITIONS</strong>
              </li>
            </ol>
            <p className="mb-2 mt-2">
              <strong>&ldquo;Authorized Third Parties&rdquo; </strong>means the
              Licensee&rsquo;s Users, Licensee&rsquo;s Affiliates,
              Licensee&rsquo;s third-party service providers, and each of their
              respective Users permitted to access and use the Software on the
              Licensee&rsquo;s behalf.
            </p>
            <p className="mb-2 mt-2">
              <strong>"Confidential Information"</strong> means this Agreement
              and all its Schedules, any addenda hereto signed by both the
              Parties, all Software listings, Documentation, information,
              drawings, benchmark tests, specifications, trade secrets, source
              code, and machine-readable copies of the Software, and any other
              proprietary information supplied to the Licensee by the Licensor,
              or by the Licensee to the Licensor.
            </p>
            <p className="mb-2 mt-2">
              <strong>"Documentation"</strong> means all written instruction
              manuals regarding the Use of the Software.
            </p>
            <p className="mb-2 mt-2">
              <strong>&ldquo;End User&rdquo;</strong> means the individuals
              (including contractors or employees) permitted to access and use
              the Software on behalf of the Licensee.
            </p>
            <p className="mb-2 mt-2">
              <strong>&ldquo;License Fee&rdquo; </strong>means the fees for the
              software license (including any renewal or extension thereof),
              Support Services, or any other product or service purchased under
              this Agreement,{" "}
              <strong>
                except during the trial period, during which the software
                license is provided free of charge.
              </strong>
            </p>
            <p className="mb-2 mt-2">
              <strong>&ldquo;Software"</strong> means a computer program(s) in
              machine-readable form, whereby intellectual property rights are
              owned by the Licensor. Software includes any updates, upgrades,
              modifications, enhancements, configurations, and customizations of
              source code, binary code, user interface, libraries, data
              integration patterns, data structures, automation patterns,
              knowledge artefacts developed or enhanced through cognitive
              computing, architecture, and all related designs and
              Documentation.
            </p>
            <p className="mb-2 mt-2">
              <strong>&ldquo;Support Services&rdquo; </strong>means the service
              for the correction of errors and/or support of the Software and
              the issuance of any updates.
            </p>
            <p className="mb-2 mt-2">
              <strong>&ldquo;Licensee Data&rdquo;</strong> means all data,
              including all text or image files, audio-visual files, and
              personally identifiable information, which are owned and provided
              by the Licensee.
            </p>
            <p className="">
              <strong>&ldquo;Updates&rdquo; </strong>means all subsequent
              releases and versions of the Software that the Licensor makes
              generally available to its customers as part of purchased Support
              Services and which are not separately priced or marketed by the
              Licensor.
            </p>
            <p>&nbsp;</p>
            <ol start="2">
              <li className="">
                <strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
                <ul className="list-disc ml-5 mb-4">
                  <li className="mt-2">
                    Title to the Software, Documentation, Updates, and all
                    patents, copyrights, trade secrets, and other worldwide
                    proprietary and intellectual property rights in or related
                    thereto are and will remain the exclusive property of the
                    Licensor.
                  </li>
                  <li className="mt-2">
                    Customer may not remove any titles, trademarks, trade names,
                    copyright notices, or other proprietary markings in or on
                    the Software, hardware, or Documentation and will not
                    acquire any rights in the Software, except the limited
                    license specified in this Agreement.
                  </li>
                  <li className="mt-2">
                    The Licensor owns all rights in any copy, translation,
                    modification, adaptation, or Derivative Works of the
                    Software, including any improvement or development thereof.|
                    Licensor retains all rights not expressly granted to
                    Licensee in this Agreement. Licensee shall promptly notify
                    the Licensor in writing upon discovery of any unauthorized
                    use of the Software or Documentation or infringement of the
                    Licensor's proprietary rights in the Software or
                    Documentation.
                  </li>
                  <li className="mt-2">
                    The Software includes certain third-party software as set
                    forth in the Documentation and may be used only in
                    accordance with the licenses set forth therein, which
                    prevail over the terms of this Agreement with respect to
                    such third-party software. With respect to the Software,
                    third-party components are integrated by the Licensor for
                    and on behalf of the Licensee in accordance with this
                    Agreement. Therefore, any third-party software delivered to
                    the Licensee pursuant to this Agreement, including any
                    third-party plug-in that may be provided with the Software,
                    is included for use at the Licensee's option, solely in
                    accordance with the corresponding third-party software
                    license(s). The Licensor shall have no liability for
                    Licensee's use of any third-party software.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="3">
              <li>
                <strong>3. LICENSE GRANT AND RESTRICTIONS</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mt-2">
                    <strong >License Grant</strong>
                    <ul className="list-disc ml-5 mb-4">
                      <li className="mt-2">
                        Subject to the terms and conditions of this Agreement
                        and for so long as this Agreement is in force, the
                        Licensor grants to the Licensee a nonexclusive,
                        non-transferable, non-sub-licensable, term-based,
                        fee-based, revocable (upon breach of this Agreement),
                        limited license rights to access and use the Software
                        for the purposes defined in this section. Licensee
                        grants a non-exclusive, royalty-free, worldwide
                        term-based license to use the Licensee Data during the
                        Term as mentioned in the order form for the purposes of
                        machine learning and autonomous decision-making by the
                        Software. Licensee may permit Authorized Third Parties
                        to exercise the limited license rights to Use on the
                        Licensee behalf, provided that Licensee is responsible
                        for (a) ensuring that such Authorized Third Parties
                        comply with this Agreement and (b) any breach of this
                        Agreement by such Authorized Third Parties.
                      </li>
                      <li className="mt-2">
                        Notwithstanding anything contained in this Agreement,
                        the Licensee agrees that they can copy the Software for
                        backup or archival purposes only, provided all titles,
                        trademark symbols, copyright symbols and legends, and
                        other proprietary markings are reproduced as it is.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>License Restriction</strong>
                    <ul className="list-disc ml-5 mb-4">
                      <li className="mt-2">
                        Licensee agrees that it will not itself, or through any
                        parent, subsidiary, affiliate, agent, or other third
                        party sell, lease, license, sublicense, encumber or
                        otherwise deal with any portion of the Software or
                        Documentation;
                      </li>
                      <li className="mt-2">
                        Licensee agrees that it will not itself, or through any
                        parent, subsidiary, affiliate, agent, or other third
                        party shall not copy, modify, perform reverse
                        engineering, decompile, disassemble, or attempt to
                        derive the source code of the Software.
                      </li>
                      <li className="mt-2">
                        Licensee agrees that it will not itself, or through any
                        parent, subsidiary, affiliate, agent, or other third
                        party not to use Software for any purpose that is
                        illegal or otherwise violates any applicable laws or
                        regulations; use the Software for any purpose that
                        infringes the intellectual property or privacy rights of
                        any third party; or use an automated system to interact
                        with the Software, except where explicitly permitted by
                        the Licensor.
                      </li>
                      <li className="mt-2">
                        Licensee agrees that it will not remove, alter, or
                        obscure any proprietary notice (including any notice of
                        copyright or trademark) of the Company or its
                        affiliates, partners, suppliers, or the Licensors of the
                        application.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <ol start="4">
              <li>
                <strong>4. TRIAL PERIOD </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    Subject to the terms and conditions of this Agreement, the
                    Licensor may, at its sole discretion, offer the Licensee a
                    limited, non-exclusive, non-transferable, non-sub
                    licensable, revocable trial license to access and use the
                    Software for evaluation purposes. This trial license is
                    granted free of charge and is valid for a specified trial
                    period as indicated in the MOU, subscription agreement,
                    order form or as mutually decided by the Parties. During the
                    trial period, the Licensee may use the Software solely for
                    internal testing and evaluation and not for any production
                    or commercial purposes.
                  </li>
                  <li className="mb-2">
                    The trial period shall commence on the date the Licensee
                    first receives access to the Software and shall continue for
                    the duration specified in the Documentation or as otherwise
                    communicated in writing by the Licensor. Unless the Licensee
                    purchases a full license to the Software before the end of
                    the trial period, the Licensee&rsquo;s access to the
                    Software will automatically terminate at the end of the
                    trial period without further notice.
                  </li>
                  <li className="mb-2">
                    During the trial period, the Licensee must comply with all
                    usage restrictions set forth in this Agreement. In addition
                    to these restrictions, the Licensee acknowledges that the
                    Software may contain features or limitations that differ
                    from the fully licensed version, including, but not limited
                    to, reduced functionality or time limits on access.
                  </li>
                  <li className="mb-2">
                    The Software provided during the trial period is made
                    available &ldquo;as-is&rdquo; without any warranties,
                    express or implied, including but not limited to warranties
                    of merchantability, fitness for a particular purpose, or
                    non-infringement. The Licensor shall have no liability
                    whatsoever for any damages arising out of or in connection
                    with the use of the Software during the trial period.
                  </li>
                  <li className="mb-2">
                    The Licensor reserves the right to terminate the trial
                    license at any time for any reason. Upon termination or
                    expiration of the trial period, the Licensee must cease all
                    use of the Software and destroy any copies of the Software
                    in its possession.
                  </li>
                  <li className="mb-2">
                    If the Licensee wishes to continue using the Software after
                    the expiration of the trial period, the Licensee must
                    purchase a full license under the terms and conditions set
                    forth in this Agreement.
                  </li>
                </ul>
              </li>
              <li>
                <strong>5. THIRD PARTY</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    The Licensor acknowledges that for the proper functioning of
                    Software, it may integrate third-party proprietary software
                    or open-source software (collectively,{" "}
                    <strong>"Third-Party Software"</strong>). The Licensor
                    Software has acquired the necessary rights to use such
                    software for the required functionalities of the Software.
                    Any use of Third-Party Software may be subject to additional
                    terms and conditions beyond those set forth in this
                    Agreement.
                  </li>
                  <li className="mb-2">
                    The Licensee acknowledges and agrees (a) Third-party
                    Licensors retain ownership of their respective Third-Party
                    Software included in the Licensor&rsquo;s Software. (b) Such
                    Third-party Licensors are beneficiaries of this Agreement,
                    and they are entitled to enforce their rights, including
                    seeking legal and equitable remedies for any breach by the
                    Licensee.
                  </li>
                  <li className="mb-2">
                    If a Licensee uses the Licensor&rsquo;s Software together
                    with third-party hardware or other software products that
                    are not natively part of the platform, such use is at the
                    Licensee's risk. The Licensor&rsquo;s Software does not
                    guarantee support or ongoing integration compatibility for
                    non-native products unless specifically mentioned in the
                    agreement.
                  </li>
                  <li className="mb-2">
                    The Licensee acknowledges and agrees that the Licensor shall
                    not be responsible for any Third-party Services, including
                    their accuracy, completeness, timeliness, validity,
                    copyright compliance, legality, decency, quality, or any
                    other aspect thereof. The Licensor does not assume and shall
                    not have any liability or responsibility to the Licensee or
                    any other person or entity for any third-party services.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="6">
              <li>
                <strong>6. LICENSE FEES &amp; PAYMENTS TERMS</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    In consideration of the rights granted under this Agreement,
                    the Licensee shall pay the applicable subscription fees
                    "License Fee" as specified in the billing information
                    provided by the Licensor. Unless otherwise agreed, all
                    License Fees are payable within 45 days from the date of
                    invoice issuance and upon submission of any required tax
                    identification forms.
                  </li>
                  <li className="mb-2">
                    Except as otherwise provided in this Agreement, all License
                    Fees paid are non-refundable. If the Licensee defaults on
                    any payments, the Licensor reserves the right to charge an
                    interest rate of 10% per month on the outstanding balance.
                  </li>
                  <li className="mb-2">
                    All fees are exclusive of any applicable duties, taxes, or
                    levies as required by law. These taxes shall be paid by the
                    Licensee at the applicable rate and in accordance with the
                    law.
                  </li>
                  <li className="mb-2">
                    The Licensor shall invoice the Licensee for the subscription
                    and usage fees of the Software upon the signing of the
                    subscription agreement or order form. Payments must be made
                    in accordance with the terms set out in this Agreement or as
                    stipulated in the order form signed between the Parties.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="7">
              <li>
                <strong>7. LICENSE AUDIT RIGHTS</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    The Licensor reserves the right, upon providing reasonable
                    prior written notice to the Licensee, to conduct an audit of
                    the Licensee&rsquo;s use of the Software to verify
                    compliance with this Agreement, including proper use of the
                    subscription, adherence to data privacy regulations, and
                    payment of applicable license fees.
                  </li>
                  <li className="mb-2">
                    The Licensee and Licensor agree the audit will be conducted:
                    (a) At a mutually agreeable time and no more than once in
                    any 12 month period. (b) By the Licensor or an independent
                    audit firm selected by the Licensor. (c) During normal
                    business hours, in a manner that does not unreasonably
                    interfere with the Licensee&rsquo;s normal operations.
                  </li>
                  <li className="mb-2">
                    The audit will be conducted at the expense of the Licensor
                    unless the audit reveals that the Licensee has underpaid
                    subscription or license fees or has not adhered to the terms
                    of the Agreement. In such cases, the Licensee will be
                    required to reimburse the Licensor for all reasonable costs
                    incurred during the audit, along with any unpaid or
                    underpaid fees.
                  </li>
                  <li className="mb-2">
                    The Licensor may report audit results to Third-Party
                    Licensors of any Third-Party Software used within the
                    Licensor&rsquo;s Software if applicable.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="8">
              <li>
                <strong>8. SUPPORT &amp; MAINTENANCE </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    Upon payment of the appropriate subscription or support fee,
                    the Licensee shall be entitled to receive technical support
                    and assistance from Licensors as described in the service
                    agreement or order form associated with the Software.
                    Support services include help with the use of the
                    platform&rsquo;s dashboards, job vacancy postings, student
                    profile management, and related functionalities.
                  </li>
                  <li className="mb-2">
                    Any updates, modifications, or customizations made to the
                    Software as part of the support services provided by the
                    Licensor, including any derivative works or software code,
                    shall be considered part of the Software and subject to this
                    Agreement.
                  </li>
                  <li className="mb-2">
                    If the Licensee provides any technical information to the
                    Licensor during support-related activities, the Licensor may
                    use such information for the purpose of improving or
                    developing the platform. However, any such information will
                    be anonymized and will not be used in a way that personally
                    identifies the Licensee or its users, including students,
                    companies, or college staff.
                  </li>
                  <li className="mb-2">
                    The Licensor reserves the right to perform scheduled
                    maintenance on the Software, which may cause temporary
                    disruptions to the platform&rsquo;s services. Licensees will
                    be notified in advance of such maintenance. Disruptions will
                    be minimized, and services will be restored as soon as
                    possible.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="9">
              <li>
                <strong>9. WARRANTY AND LIABILITY</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li>
                    <strong>Warranties </strong>
                  </li>
                </ul>
              </li>
            </ol>
            <p className="mb-2">The Licensor represents and warrants that:</p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                The Licensor has the authority to enter into this Agreement with
                the Licensee (colleges, companies, or institutions).
              </li>
              <li className="mb-2">
                To the best of the Licensor&rsquo;s knowledge, the Software
                platform does not, at the time of delivery, contain any
                malicious code or defects that could adversely affect the
                operation, security, or integrity of the Licensee's systems.
              </li>
              <li className="mb-2">
                All support services will be performed in a professional and
                workmanlike manner by qualified personnel.
              </li>
              <li className="mb-2">
                At the time of delivery, the Software is not subject to any
                claims or allegations of intellectual property infringement. If
                the Licensor&rsquo;s becomes aware of any such claims or facts
                that could lead to such claims, it will promptly notify the
                Licensee.
              </li>
              <li className="mb-2">
                Except as expressly set forth above, to the maximum extent
                permitted by applicable law, the Licensor makes no further
                warranties, whether express, implied, or statutory, regarding
                the Software, its Documentation, or any materials or services
                provided under this Agreement, including but not limited to the
                following: (a) The Software does not warrant that the Software
                will be error-free or that it will function without
                interruptions; (b) All implied warranties of merchantability,
                fitness for a particular purpose, and non-infringement are
                expressly disclaimed, even if the Licensor has been informed of
                the intended purpose of the Software.
              </li>
            </ul>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                <strong>Limitation of Liability </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    In no event shall the Licensor be liable for (a) any loss of
                    profits, loss of use, business interruption, loss of
                    Licensee data, or any indirect, special, incidental, or
                    consequential damages arising from or related to the use,
                    performance, or provision of the Software, even if the
                    Licensor was informed of the possibility of such damages.
                    (b) any delay in the delivery of services or performance of
                    support services.
                  </li>
                  <li className="mb-2">
                    The Licensor&rsquo;s total liability for any damages,
                    whether in contract or any other form of liability, arising
                    from or related to (a) non-support-related claims will not
                    exceed the subscription fees paid for the use of the
                    Software in the preceding 12 months. (b) support-related
                    claims will not exceed the total support fees paid by the
                    Licensee in the preceding 6 months for the applicable
                    support term.
                  </li>
                </ul>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol start="10">
              <li>
                <strong>10 INDEMNIFICATION </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    <strong>Indemnification for Infringement </strong>
                    <ul className="list-disc ml-5 mb-4 mt-2">
                      <li className="mb-2">
                        The Licensor shall, at its own expense, defend or settle
                        any claim, action, or allegation brought against the
                        Licensee alleging that the use of the Software infringes
                        any patent, copyright, trade secret, or other
                        proprietary rights of a third party, provided that (a)
                        The Licensee gives prompt written notice to the Licensor
                        of any such claim, action, or allegation. (b) The
                        Licensee provides the Licensor with the necessary
                        authority and information to handle the claim. (c) The
                        Licensor will have the exclusive right to defend such
                        claims and make settlements at its discretion. The
                        Licensee may not settle or compromise such claims
                        without the prior written consent of the Licensor.
                      </li>
                      <li>
                        In the event that any infringement claim, action, or
                        allegation is brought or threatened, the Licensor may,
                        at its sole option and expense (a) Procure for the
                        Licensee the right to continue using the Software or the
                        infringing part thereof; (b) Modify or replace the
                        infringing part of the Software with another version
                        that has substantially the same or better
                        functionalities; or (c) If neither of the above options
                        is commercially practicable, terminate the Agreement
                        with respect to the infringing part of the Software and
                        refund the portion of any fees paid by the Licensee for
                        the remainder of the subscription period related to the
                        infringing part of the Software.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    <strong>Exclusions to Indemnification</strong>
                    <ul className="list-disc ml-5 mb-4 mt-2">
                      <li className="mb-2">
                        The Licensor shall have no obligation to defend or
                        settle any claim if: (a) The claim is based on the
                        combination or use of the Software with any software,
                        hardware, or device not provided by the Licensor, where
                        the infringement would not have occurred but for such
                        combination or use. (b) The claim results from the
                        Licensee&rsquo;s failure to use the most current version
                        of the Software provided by the Licensor. (c) The claim
                        results from unauthorized use of the Software, or use
                        that is inconsistent with this Agreement or the
                        associated Documentation. (d) The claim is related to
                        the amount or duration of the Licensee&rsquo;s use of
                        the Software, or services offered by the Licensee using
                        the Software. (e) The infringement continues after the
                        Licensor has offered modifications or workarounds free
                        of charge that would have avoided the infringement while
                        maintaining substantially the same functionalities.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    The Licensee agrees to indemnify and defend the Licensor,
                    its affiliates, employees, and directors against any
                    third-party claim alleging that any data provided by the
                    Licensee, including student or company profiles, infringes
                    or misappropriates that third party&rsquo;s intellectual
                    property rights. The Licensee shall bear all costs,
                    settlements, and final judgments resulting from such claims.
                  </li>
                  <li>
                    The Licensee shall defend and indemnify the Licensor against
                    any claims, actions, or allegations arising from a breach of
                    the grant of license or unauthorized use of the Software by
                    the Licensee.
                  </li>
                  <li>
                    The Licensee shall not be entitled to recover any amounts
                    under this Agreement in respect of a claim if it has already
                    recovered such amounts under warranties or any other
                    provision of this Agreement, or under any statutory right or
                    agreement related to the same subject matter.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="11">
              <li>
                <strong>11. CONFIDENTIALITY</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    Each Party acknowledges that the Confidential Information
                    shared between the Licensor and the Licensee as part of the
                    use of the Software constitutes valuable trade secrets. Both
                    Parties agree to use the Confidential Information solely for
                    the purposes outlined in this Agreement. The parties agree
                    not to disclose or permit the disclosure of the Confidential
                    Information to any third party without the prior written
                    consent of the other Party, except as required for the
                    operation of the Software.
                  </li>
                  <li className="mb-2">
                    Each Party agrees to exercise due care to protect the
                    Confidential Information from unauthorized use or
                    disclosure. However, neither Party shall be responsible for
                    safeguarding information that: (a) Is publicly available at
                    the time of disclosure; (b) Is lawfully obtained from a
                    third party without restrictions on disclosure; (c) Is
                    independently developed by the receiving Party without
                    reference to the Confidential Information; or (d) Is
                    required to be disclosed by order of a court, regulatory
                    authority, or other governmental entity, provided that the
                    receiving Party gives prompt notice to the disclosing Party
                    of such a requirement, if legally permissible
                  </li>
                  <li className="mb-2">
                    These confidentiality obligations shall survive the
                    termination or expiration of this Agreement. Both Parties
                    agree to continue protecting Confidential Information even
                    after the end of the Agreement.
                  </li>
                  <li className="mb-2">
                    In the event of an actual or threatened breach of this
                    Confidentiality Clause, the non-breaching Party shall be
                    entitled to seek immediate injunctive relief and any other
                    equitable remedies available under the law, without the need
                    to post bond or prove actual damages.
                  </li>
                  <li className="mb-2">
                    The Licensee shall ensure that any personnel, including
                    staff, employees, agents, or students, who have access to
                    Confidential Information, sign a confidentiality agreement
                    substantially similar to the terms of this Section. The
                    Licensee is responsible for ensuring compliance with these
                    confidentiality provisions by its personnel.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="12">
              <li className="mb-2">
                <strong>TERM AND TERMINATION</strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li>
                    <strong>Terms </strong>
                    <ul className="list-disc ml-5 mb-4 mt-2">
                      <li className="mb-2">
                        This Agreement commences on the Effective Date and will
                        remain in force for the term specified in the
                        subscription agreement or order form{" "}
                        <strong>(&ldquo;Initial Term&rdquo;).</strong> It shall
                        automatically renew for the renewal period indicated in
                        the order form{" "}
                        <strong>(&ldquo;Renewal Term&rdquo;)</strong>, unless:
                        (a) This Agreement is terminated earlier in accordance
                        with its terms; (b) Either Party provides written notice
                        to the other of its decision not to renew at least 30
                        days prior to the end of the Initial Term or the current
                        Renewal Term, as applicable; or (c) The Licensee opts
                        out of automatic renewal at the time of the initial
                        subscription with the Licensor.
                      </li>
                      <li className="mb-2">
                        If the Licensee chooses to terminate the Agreement, such
                        termination will not entitle the Licensee to a refund of
                        any fees paid to the Licensor. The Licensee must notify
                        the Licensor in writing of its decision regarding
                        renewal at least 30 days prior to the expiration of the
                        current term. This Agreement may be renewed upon mutual
                        consent of the Parties.
                      </li>
                      <li className="mb-2">
                        The Licensor will notify the Licensee reasonably in
                        advance of any renewal if there are changes in fees. The
                        new fees will apply for the upcoming Renewal Term unless
                        the Licensee promptly notifies the Licensor in writing,
                        before the renewal date, that it does not accept the fee
                        changes. In such a case, the Agreement will terminate at
                        the end of the current term. The Initial Term and any
                        Renewal Term(s) will together be referred to as the{" "}
                        <strong>&ldquo;Term.&rdquo;</strong>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    <strong>Termination for Cause</strong>
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              The Licensor may terminate this Agreement by providing written
              notice to the Licensee if any of the following Termination Events
              occur:
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                The Licensee is in breach of this Agreement and does not cure
                the breach within 30 days of receiving written notice from
                Licensor.
              </li>
              <li className="mb-2">
                The Licensee terminates its business operations, becomes
                insolvent, or is unable to pay its debts as they become due.
                This includes any assignment for the benefit of creditors or the
                appointment of a trustee or receiver.
              </li>
              <li className="mb-2">
                The Licensee fails to pay the subscription or any other fees
                owed under this Agreement.
              </li>
              <li className="mb-2">
                The Licensee uses the Software in violation of the terms of this
                Agreement or for any unlawful purpose.
              </li>
              <li className="mb-2">
                In the event of termination under this section, the Licensee
                will not be entitled to a refund of any fees paid.
              </li>
            </ul>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                Termination will become effective either immediately or on the
                date specified in the written notice of termination provided by
                the Licensor. Termination of this Agreement will not affect: (a)
                The treatment of Confidential Information;(b) Payment
                obligations for any fees due; (c) Provisions limiting or
                disclaiming liability; (d) Non-solicitation provisions or any
                applicable laws;(e) Any other provisions that, by their nature,
                are intended to survive the termination of the Agreement. These
                provisions will survive the termination of this Agreement.
              </li>
              <li className="mb-2">
                Within 30 days after the termination or expiration of this
                Agreement, the Licensee shall: (a) Return or securely destroy
                all copies of the Software, any derivative works, related
                Documentation, and all other Confidential Information received
                from the Licensor; (b) Confirm to The Licensor in writing that
                all such materials have been returned or destroyed, except as
                otherwise required by law.
              </li>
            </ul>
            <ol start="13">
              <li>
                <strong>DATA PRIVACY </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li className="mb-2">
                    The Licensor acknowledges that, in the course of performing
                    its obligations under this Agreement, it may have access to
                    and process personal data relating to the Licensee (which
                    may include its students, employees, or applicants) and/or
                    other third parties (collectively, "Licensee Personal
                    Data"). The Licensor agrees that any personal data processed
                    under this Agreement must be processed solely for the
                    purposes for which it is provided, i.e., only on behalf of
                    and in accordance with the Licensee&rsquo;s documented
                    instructions.
                  </li>
                  <li className="mb-2">
                    Both Parties agree to comply with the India Digital Personal
                    Data Protection Act, 2023, and other relevant regulations.
                    In this context, the Licensor will act as a Data Processor,
                    and the Licensee will act as the Data Fiduciary.
                  </li>
                  <li>
                    The Parties agree that the Licensor shall:
                    <ul className="list-disc ml-5 mb-4 mt-2">
                      <li className="mb-2">
                        Implement appropriate technical and organizational
                        security measures to safeguard Licensee Personal Data
                        against accidental or unlawful destruction, accidental
                        loss, alteration, unauthorized disclosure, or access,
                        especially during data transmission over networks. The
                        Licensor agrees to provide a detailed list of such
                        security measures upon the Licensee&rsquo;s request.
                      </li>
                      <li className="mb-2">
                        Process Licensee Personal Data solely for the purpose of
                        fulfilling the terms of this Agreement and not for any
                        other purpose without explicit, documented instructions
                        from the Licensee.
                      </li>
                      <li className="mb-2">
                        Restrict access to Licensee Personal Data to only those
                        employees, agents, or subcontractors of the Licensor who
                        need access to perform the services. The Licensor will
                        take reasonable steps to ensure the reliability of
                        individuals or entities with access to such data,
                        ensuring they comply with the confidentiality
                        obligations of this Agreement.
                      </li>
                      <li className="mb-2">
                        Immediately notify the Licensee in the event of any
                        actual or potential data breach and take steps to
                        mitigate such breaches as required by law; Notify the
                        Licensee of any request received directly from a data
                        subject concerning the Licensee&rsquo;s Personal Data,
                        without responding to the request unless explicitly
                        authorized by the Licensee; Notify the Licensee of any
                        legally binding request for disclosure of Licensee
                        Personal Data by a law enforcement authority, unless
                        such notification is prohibited by law.
                      </li>
                      <li className="mb-2">
                        Upon the termination or expiration of this Agreement, or
                        upon the Licensee&rsquo;s request, the Licensor shall
                        securely delete or return all Licensee Personal Data and
                        any copies thereof. The Licensee may request the
                        Licensor to delete, amend, or modify the personal data
                        at any time during the term of the Agreement.
                      </li>
                      <li className="mb-2">
                        Defend, indemnify, and hold harmless the Licensee, its
                        affiliates, and customers from and against any and all
                        liabilities, costs, damages, fines, claims, or losses
                        (including legal fees) arising from any breach of data
                        protection obligations or unauthorized use of personal
                        data by the Licensor.
                      </li>
                      <li className="mb-2">
                        Not engage any sub-processors without the prior written
                        consent of the Licensee. Any sub-processor must enter
                        into a contract with equivalent data protection
                        obligations as those outlined in this provision. The
                        Licensor agrees to provide a copy of any sub-processor
                        agreement upon request.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    Both Parties agree to comply with all applicable provisions
                    of the India Digital Personal Data Protection Act, 2023 and
                    ensure that personal data is processed, stored, and secured
                    in compliance with the standards set forth by Indian law.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="14">
              <li>
                <strong>14. NON-ASSIGNMENT/BINDING AGREEMENT </strong>
              </li>
            </ol>
            <p className="mb-2">
              Neither this Agreement nor any rights under this Agreement may be
              assigned or otherwise transferred by Licensee, in whole or in
              part, whether voluntary or by operation of law, including by way
              of sale of assets, merger, or consolidation, without the prior
              written consent of the Licensor, which consent will not be
              unreasonably withheld.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <ol start="15">
              <li>
                <strong>15. GENERAL PROVISIONS </strong>
                <ul className="list-disc ml-5 mb-4 mt-2">
                  <li>
                    <strong>Force Majeure</strong>
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Neither Party will incur any liability to the other Party on
              account of any loss or damage resulting from any delay or failure
              to perform all or any part of this Agreement if such delay or
              failure is caused, in whole or in part, by events, occurrences, or
              causes beyond the reasonable control and without negligence of the
              Parties. Such events, occurrences, or causes will include, without
              limitation, acts of God, strikes, lockouts, riots, acts of war,
              earthquakes, fire, and explosions, but the inability to meet
              financial obligations is expressly excluded.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                <strong>Severability</strong>
              </li>
            </ul>
            <p>
              If any term, condition, or provision in this Agreement is found to
              be invalid, unlawful, or unenforceable to any extent, the Parties
              shall endeavor in good faith to agree to such amendments that will
              preserve, as far as possible, the intentions expressed in this
              Agreement.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li>
                <strong>Non-Solicitation of Personnel </strong>
              </li>
            </ul>
            <p>
              The Licensee agrees that for the duration of this Agreement and
              for a period of one (1) year after its termination or expiration,
              the Licensee will not, directly or indirectly: (a) Recruit,
              solicit, or discuss employment with; (b) Hire, employ, or engage;
              any personnel of the Licensor who are assigned to provide any
              aspect of the services related to the Software, or who were
              assigned within the previous one (1) year period. Additionally,
              the Licensee agrees not to induce any such individual to leave the
              employment of the Licensor for any reason during this period.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li>
                <strong>Entire Agreement </strong>
              </li>
            </ul>
            <p>
              This agreement constitutes the entire agreement between the
              parties and supersedes all written and oral prior agreements or
              understandings between the parties.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li>
                <strong>Modification of Agreement: </strong>
              </li>
            </ul>
            <p>
              The Licensor reserves the right, at any time, to revise,
              supplement, or modify this Agreement, including imposing new or
              additional rules, policies, terms, or conditions (collectively,{" "}
              <strong>"Additional Terms"</strong>) on the Licensee's use of the
              Software. Such Additional Terms will be effective immediately upon
              communication of the revised Agreement. By continuing to use the
              Software after such revisions, the Licensee acknowledges and
              agrees to the updated terms of the Agreement.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li>
                <strong>Notices and Communications</strong>
                <ul>
                  <li>
                    The Licensor may send the Licensee required legal notices,
                    updates regarding the Software special offers, pricing
                    information, customer surveys, or other requests for
                    feedback (collectively, "Communications"). The Licensor will
                    send such Communications through one of the following
                    channels: (a) In-platform notifications on the SOFTWARE; (b)
                    Email to the registered email addresses of designated
                    contacts of the Licensee; or (c) Postings on the
                    Licensor&rsquo;s official website.
                  </li>
                  <li>
                    The Licensee may notify the Licensor of its preference not
                    to receive certain Communications (which may impact the
                    Licensee&rsquo;s use of the Software and the provision of
                    support services). However, important legal notices and
                    information critical to the functionality of the platform
                    cannot be opted out of.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Waiver </strong>
              </li>
            </ul>
            <p>
              No failure of either party to exercise or enforce any of its
              rights under this agreement will act as a waiver of those rights.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                <strong>Public Announcements</strong>
              </li>
            </ul>
            <p>
              Licensee agrees that the Licensor may use its name in press
              releases, product brochures, and financial reports indicating that
              Licensee is a Licensee of the Licensor.
            </p>
            <ul className="list-disc ml-5 mb-4 mt-2">
              <li className="mb-2">
                <strong>Applicable Law and Dispute Resolution </strong>
              </li>
            </ul>
            <p>
              This agreement shall be governed by and any dispute shall be
              resolved in accordance with the Laws of India, excluding its
              conflict of law principles.
            </p>
            <p>&nbsp;</p>
            </div>
            <div className="flex justify-between border-t border-gray-300 pt-4">
            <Button variant="primary" className="py-0 w-[8rem] rounded-3xl text-md" onClick={onAccept}>Accept</Button>
            <Button variant="primary" className="py-0 w-[8rem] rounded-3xl text-md" onClick={onDecline}>Decline</Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPolicy;