export const ApplicantSemesterColumn = [
    {
      accessorKey: "semester",
      header: "Semester",
    },

    {
      accessorKey: "spi",
      header: "SPI",
    },

    {
      accessorKey: "cpi",
      header: "CPI",
    },

    {
      accessorKey: "sgpa",
      header: "SGPA",

    },
    {
      accessorKey: "cgpa",
      header: "CGPA",

    },
  ];